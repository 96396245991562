import { srConfig } from '@config';
import { usePrefersReducedMotion } from '@hooks';
import sr from '@utils/sr';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useEffect, useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

const StyledAboutSection = styled.section`
  max-width: 900px;

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;

const Skill = styled.div`
padding: 10px;
border: solid 1px #fff;
width: auto;
white-space: nowrap;
border-radius: 5px;
margin-right: 10px;
margin-bottom: 10px;
`

const StyledPic = styled.div`
  position: relative;
  max-width: 300px;

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--green);

    &:hover,
    &:focus {
      background: transparent;
      outline: 0;

      &:after {
        top: 15px;
        left: 15px;
      }

      .img {
        filter: none;
        mix-blend-mode: normal;
      }
    }

    .img {
      position: relative;
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      filter: grayscale(100%) contrast(1);
      transition: var(--transition);
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--navy);
      mix-blend-mode: screen;
    }

    &:after {
      border: 2px solid var(--green);
      top: 20px;
      left: 20px;
      z-index: -1;
    }
  }
`;

const About = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading">About Me</h2>

      <div className="inner">
        <StyledText>
          <div>
            <p>
              Hello! My name is Casey. I enjoy creating software and products things that live on the internet. My
              interest in web technologies started back in the 90's when I was just a kid. Ever since then I have been building software, platforms and businesses on the web.
            </p>
          </div>

          <div style={{ marginTop: 20 }}>
            <p><strong style={{ color: "#fff" }}>Areas of interest / experience:</strong></p>
            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", marginTop: 20 }}>
              <Skill data-for="product" data-tip="Design, scope & build product features using scrum and agile methodologies. Experience following the Shape-Up method for Product Management & Delivery Cadence.">Product</Skill>
              <Skill data-for="cloud" data-tip="Deep experience in AWS, CCP, DO & other cloud providers running Linux distros. <br/> Oversaw build and deployment of infrastructure with 20+ instances. Vast knowledge of EC2, RDS, S3, Route53, etc.">Cloud Deployments</Skill>
              <Skill data-for="devops" data-tip="Hands on experience in managing cloud deployment of 20+ instances, to include <br/> load balancing, failovers, read replication, etc. Experienced in Linux distributions, databases (PostgreSQL \/ MySQL), dns, cdn, etc.">Dev Ops</Skill>
              <Skill data-for="gtm" data-tip="Go to market and growth experience from a founders point of view. Experienced in agile <br/> development processes (Jira, Scrum \/ Kanban), scaling and growth hacking. Managed worldwide team of developers with 2x annual in-person sprints.">GTM</Skill>
              <Skill data-for="frontend" data-tip="Deep experienced in frontend development including CSS, React.js, Javascript, <br/> APIs, typescript, node, postgreSQL, mySQL, graphQL, Gatsby.">Front End</Skill>
              <Skill data-for="backend" data-tip="Backend experience includes Node, Sequelize, Express.js, etc.">Back End</Skill>
              <Skill data-for="engineering" data-tip="Deep experience managing worldwide development team including recruiting, <br/> hiring & managing. Hands on leader with real-world experience in modern tech stacks.">Engineering</Skill>
            </div>
          </div>

          <div>
            <p><strong style={{ color: "#fff" }}>Past Clients / Projects:</strong></p>
            <ul className="skills-list">
              <li key='ff'>flatfile.com</li>
              <li key='gc'>goodcover.com</li>
              <li key='gc'>meetbbot.com</li>
              <li key='ph'>lastrecord.com</li>
              <li key='ph'>crewsense.com</li>
            </ul>
          </div>

        </StyledText>

        <ReactTooltip id="cloud" backgroundColor="#388777" textColor="#fff" multiline={true} />
        <ReactTooltip id="devops" backgroundColor="#388777" textColor="#fff" multiline={true} />
        <ReactTooltip id="gtm" backgroundColor="#388777" textColor="#fff" multiline={true} />
        <ReactTooltip id="frontend" backgroundColor="#388777" textColor="#fff" multiline={true} />
        <ReactTooltip id="backend" backgroundColor="#388777" textColor="#fff" multiline={true} />
        <ReactTooltip id="engineering" backgroundColor="#388777" textColor="#fff" multiline={true} />

        <StyledPic>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/casey.jpg"
              width={500}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>
        </StyledPic>
      </div>
    </StyledAboutSection>
  );
};

export default About;
